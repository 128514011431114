import React from "react";
import { useFetchCollectionAPI } from "../../custom-hooks/useFetchCollectionsAPI";
import { Member } from "../../interfaces/members";

type MembersCpntProps = {
  title: string;
  type: string;
};

const MembersTypeCpnt = ({ title, type }: MembersCpntProps) => {

  const [isMembersCollectionLoading, membersCollection] = useFetchCollectionAPI(
    process.env.REACT_APP_API_URL + "/admin/api/collections/get/members"
  );
  let membersCollectionsSorted: Member[] = [];
  if (membersCollection) {
    membersCollectionsSorted = membersCollection.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  if (isMembersCollectionLoading) return <p>Loading...</p>;
  return (
    <article className='members-type-article'>
      <section className='mta-section'>
        <h2 className='mtas-title' id='members-cpnt'>
          {title}
        </h2>
        <div className='mfas-images-wrapper'>
          {membersCollectionsSorted?.map((member, index) => {
            if (member.types.includes(type))
              return (
                <div className='mfasiw-item' key={index}>
                  <a className='mfasiwi-link' href='' target='_blank' rel='noreferrer'>
                    <div
                      className='mfasiwi-image'
                      style={{
                        backgroundImage: `url( ${process.env.REACT_APP_API_URL + "/" + member.image})`,
                      }}
                    ></div>
                  </a>
                </div>
              );
          })}
        </div>
      </section>
    </article>
  );
};

export default MembersTypeCpnt;
