import axios from "axios";
import { useEffect, useState } from "react";
import { Member } from "../interfaces/members";

export const useFetchCollectionAPI = (collection: string) => {
  const [isMembersCollectionLoading, setIsMembersCollectionLoading] = useState(true);
  const [membersCollection, setMembersCollection] = useState<Member[]>();

  const fetchMembersCollection = async (): Promise<any> => {
    const response = await axios.get(collection);
    const result = response.data.entries;
    const members: Member[] = [];
    result.forEach((item: any) => {
      const types: string[] = [];
      item.type.forEach((type: any) => {
        types.push(type.display);
      });
      members.push({
        name: item.name,
        image: item.image.path,
        types,
      });
    });
    setMembersCollection(members);
    setIsMembersCollectionLoading(false);
    return result;
  };

  useEffect(() => {
    fetchMembersCollection();
  }, []);

  return [isMembersCollectionLoading, membersCollection, fetchMembersCollection] as const;
};
