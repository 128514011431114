import React from "react";
import parse from "html-react-parser";

import FounderAtm from "../../atoms/founder/FounderAtm";
//import foundersDatas from "../../datas/foundersDatas.json";
import { HomePageFoundersInterface } from "../../interfaces/homePageInterfaces";

type FoundersCpntProps = {
  content: HomePageFoundersInterface | undefined;
};

const FoundersCpnt = ({ content }: FoundersCpntProps) => {
  return (
    <article className='founders-article' id='founders'>
      <div className='fa-section-wrapper'>
        <section className='fasw-founders'>
          <div className='faswf-text'>
            {/* <h2 className="faswft-title"></h2> */}
            <div className='faswft-laius'>{content ? parse(content.text || "") : ""}</div>
          </div>
          <div className='faswf-founders-wrapper'>
            <h2 className='faswffw-title'>{content?.title}</h2>
            <div className='faswffw-item'>
              {content?.members.map((founder, index) => (
                <FounderAtm
                  founderImageSrc={process.env.REACT_APP_API_URL + founder.value.member_photo.path}
                  founderFunction={founder.value.member_position}
                  founderName={founder.value.member_name}
                  founderClassName={`faswffw-founder-${index + 1}`}
                  founderIndex={index}
                  key={index}
                />
              ))}
            </div>
          </div>
        </section>
      </div>
    </article>
  );
};

export default FoundersCpnt;
