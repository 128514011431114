import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import { HomePageCommonValuesInterface } from "../../interfaces/homePageInterfaces";

interface Values {
  value_name: string;
  value_text: string;
}

type CommonValuesCpntProps = {
  content: HomePageCommonValuesInterface | undefined;
};

const CommonValuesCpnt = ({ content }: CommonValuesCpntProps) => {
  const [cooperation, setCooperation] = useState(true);
  const [equity, setEquity] = useState(false);
  const [pragmatism, setPragmatism] = useState(false);
  const [inclusion, setInclusion] = useState(false);
  const [disruption, setDisruption] = useState(false);

  const [valueDatas, setValueDatas] = useState<Values>({
    value_name: "La coopération",
    value_text:
      "C’est l’essence même de TOOGETHER : œuvrer ensemble, car l'intelligence collective est source de créativité et de performance.",
  });

  useEffect(() => {
    if (content?.valors[0].value.value_text) {
      const result = content?.valors[0];
      if (result) setValueDatas(result?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueBtn = (value: string): void => {
    setCooperation(false);
    setEquity(false);
    setPragmatism(false);
    setInclusion(false);
    setDisruption(false);
    if (value === "cooperation") {
      setCooperation(true);
      if (content?.valors[0].value.value_text) {
        const result = content?.valors[0];
        if (result) setValueDatas(result?.value);
      }
    }
    // if (value === "equite") {
    //     setEquity(true);
    //     if (content?.valors[1].value.value_text) {
    //         const result = content?.valors[1];
    //         if (result) setValueDatas(result?.value);
    //     }
    // }

    if (value === "pragmatism") {
      setPragmatism(true);
      if (content?.valors[1].value.value_text) {
        const result = content?.valors[1];
        if (result) setValueDatas(result?.value);
      }
    }
    if (value === "inclusion") {
      setInclusion(true);
      if (content?.valors[2].value.value_text) {
        const result = content?.valors[2];
        if (result) setValueDatas(result?.value);
      }
    }
    if (value === "disruption") {
      setDisruption(true);
      if (content?.valors[3].value.value_text) {
        const result = content?.valors[3];
        if (result) setValueDatas(result?.value);
      }
    }
  };

  return (
    <article className="common-values-article" id="common-values">
      <div className="cva-sections-wrapper">
        <section className="cvasw-text-section">
          <h2 className="cvaswts-title">{content?.title}</h2>
          <div className="cvaswts-text">{content ? parse(content.text || "") : ""}</div>
          <div>
            <ul className="quotation">{content ? parse(content.quote || "") : ""}</ul>
          </div>
          <div className="cvaswts-values-wrapper">
            <h3 className="cvaswtsvw-title">{content?.valors_title}</h3>
            <div className="cvaswtsvwt-button-wrapper">
              <button
                onClick={() => handleValueBtn("cooperation")}
                className={`cvaswtsvwtbw-button ${cooperation && "active-value"}`}
              >
                {content?.valors[0].value.value_name}
              </button>
              {/* <button
                                onClick={() => handleValueBtn("equite")}
                                className={`cvaswtsvwtbw-button ${
                                    equity && "active-value"
                                }`}
                            >
                                {content?.valors[1].value.value_name}
                            </button>
                                L'équité
                            </button> */}

              <button
                onClick={() => handleValueBtn("inclusion")}
                className={`cvaswtsvwtbw-button ${inclusion && "active-value"}`}
              >
                {content?.valors[2].value.value_name}
              </button>
              <button
                onClick={() => handleValueBtn("disruption")}
                className={`cvaswtsvwtbw-button ${disruption && "active-value"}`}
              >
                {content?.valors[3].value.value_name}
              </button>
              <button
                onClick={() => handleValueBtn("pragmatism")}
                className={`cvaswtsvwtbw-button ${pragmatism && "active-value"}`}
              >
                {content?.valors[1].value.value_name}
              </button>
            </div>
          </div>
          <p className="cvaswts-dynamic-text">{valueDatas.value_text}</p>
        </section>
        <section className="cvasw-datas-section">
          {content?.stats.map((stat, index) => (
            <div className="cvaswds-data" key={index}>
              <p className="cvaswdsd-title">{stat ? parse(stat.value.stat_value || "") : ""}</p>
              <p className="cvaswdsd-text">{stat.value.stat_description}</p>
            </div>
          ))}
          {content?.stats_double.map((stat, index) => (
            <div className="cvaswds-data" key={index}>
              <p className="cvaswdsd-asterisk">{content?.annotation}</p>
              <p className="cvaswdsd-asterisk sub">{content?.sub_annotation}</p>
              <div className="cvaswdsd-first">
                <p className="cvaswdsd-title">{stat ? parse(stat.value.stat_double_value_1 || "") : ""}</p>
                <p className="cvaswdsd-text">{stat.value.stat_double_description_1}</p>
              </div>
              <div className="cvaswdsd-second">
                <p className="cvaswdsd-title">{stat ? parse(stat.value.stat_double_value_2 || "") : ""}</p>
                <p className="cvaswdsd-text">{stat.value.stat_double_description_2}</p>
              </div>
            </div>
          ))}
        </section>
      </div>
    </article>
  );
};

export default CommonValuesCpnt;
