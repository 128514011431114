import React from "react";
import { HashLink } from "react-router-hash-link";

import { scrollWithOffset } from "../../custom-functions/customFunctions";
import ArrowBack from "../../assets/svg/../../assets/svg/arrow-circle-left-outline.svg";

const Legals = () => {
    return (
        <main className="legals-page" id="legals-main">
            <article className="lp-article">
                <section className="lpa-edition lpa-section">
                    <HashLink
                        to="/#root"
                        scroll={(el) => scrollWithOffset(el, 300)}
                    >
                        <img
                            src={ArrowBack}
                            alt="Back button"
                            className="lpae-back-arrow-img"
                        />
                    </HashLink>
                    <h2 className="lpae-title legal-title">ÉDITION</h2>
                    <p className="lpae-paragraph legal-paragraph">
                        TOOGETHER – GROUPEMENT DES ENTREPRISES EN ACTION POUR
                        L’EGALITE FEMMES HOMMES
                        <br /> Association loi 1901
                    </p>
                    <a
                        href="www.toogether.re"
                        className="lpae-paragraph legal-paragraph"
                    >
                        www.toogether.re
                    </a>
                    <a
                        href="mailto:contact.toogether@gmail.com"
                        className="lpae-paragraph legal-paragraph"
                    >
                        contact.toogether@gmail.com
                    </a>
                    <br />
                    <br />
                    <p className="lpae-paragraph legal-paragraph">
                        Directeur de la publication : Kamale Issa, en sa qualité
                        de Président
                    </p>
                </section>
                <section className="lpa-hosting lpa-section">
                    <h2 className="lpah-title legal-title">HÉBERGEMENT</h2>
                    <p className="lpae-paragraph legal-paragraph">OVH SAS</p>
                    <p className="lpae-paragraph legal-paragraph">
                        www.ovh.com
                        <br /> 2 rue Kellermann 59100 Roubaix
                    </p>
                </section>
                <section className="lpa-personal-datas lpa-section">
                    <h2 className="lpapd-title legal-title">
                        DONNÉES PERSONNELLES
                    </h2>
                    <p className="lpae-paragraph legal-paragraph">
                        Vous pouvez visiter ce site Internet sans avoir à
                        décliner votre identité et à fournir des informations
                        personnelles vous concernant. Lorsque vous souhaitez
                        nous poser une question via le formulaire en ligne, nous
                        enregistrons la donnée personnelle suivante : adresse
                        e-mail. Cette information est nécessaire au traitement
                        de votre demande. En la communiquant, vous consentez à
                        ce qu’elle soit enregistrée dans un fichier informatisé,
                        destiné à gérer votre demande. Elle ne sera pas
                        communiquée à des tiers et sera conservée pendant la
                        durée d’existence de TOOGETHER. Vous pouvez accéder aux
                        données vous concernant, les rectifier ou retirer à tout
                        moment votre consentement à leur traitement, en
                        contactant notre DPD.
                    </p>
                    <br />
                    <p className="lpae-paragraph legal-paragraph">
                        Délégué à la Protection des Données : Kamale Issa, en sa
                        qualité de Président.{" "}
                        <a href="mailto:contact.toogether@gmail.com">
                            contact.toogether@gmail.com
                        </a>
                    </p>
                    <br />
                    <p className="lpae-paragraph legal-paragraph">
                        Pour plus d’informations sur vos droits, vous pouvez
                        vous adresser à la CNIL :{" "}
                        <a
                            href="http://www.cnil.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            http://www.cnil.fr
                        </a>
                        .
                    </p>
                </section>
            </article>
        </main>
    );
};

export default Legals;
