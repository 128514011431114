import React, { useEffect } from "react";

import ToogetherInfinite from "../../assets/images/toogether-infinite.png";

import CommonValuesCpnt from "../../components/common-values/CommonValuesCpnt";
import EqualityCpnt from "../../components/equality-part/EqualityCpnt";
import FoundersCpnt from "../../components/founders-part/FoundersCpnt";
import MembersCpnt from "../../components/members-part/MembersCpnt";
import NeedToJoinCpnt from "../../components/need-to-join/NeedToJoinCpnt";
// import SubventionsCpnt from "../../components/subventions/SubventionsCpnt";
import WhatsToogetherCpnt from "../../components/whats-toogether-part/WhatsToogetherCpnt";

import { useFetchHomepageAPI } from "../../custom-hooks/useFetchPagesAPI";
import TalkAboutUsCpnt from "../../components/talk-about-us-part/TalkAboutUsCpnt";
import KeyNumbersCpnt from "../../components/key-numbers/KeyNumbersCpnt";
import MemberTypeCpnt from "../../components/members-type-part/MembersTypeCpnt";
import SupportUsCpnt from "../../components/support-us-part/SupportUsCpnt";

const Home = (): JSX.Element => {
  const [
    isLoading,
    equalityPart,
    commonValues,
    joinUs,
    shareValues,
    // toogetherOrNot,
    keyNumbers,
    founders,
    members,
    // partner,
    cta,
    talking,
    whatsToogether,
    // subventions,
    fetchHomePageAPI,
  ] = useFetchHomepageAPI(process.env.REACT_APP_API_URL + "/admin/api/singletons/get/Accueil");

  useEffect(() => {
    fetchHomePageAPI();
  }, []);

  if (isLoading) {
    return (
      <main className="home">
        <div className="h-loader-wrapper">
          <img src={ToogetherInfinite} alt="loader" className="hlw-home-loader" />
        </div>
      </main>
    );
  } else {
    return (
      <main className="home">
        <EqualityCpnt content={equalityPart} />
        <CommonValuesCpnt content={commonValues} />
        <NeedToJoinCpnt content={joinUs} />
        <WhatsToogetherCpnt content={whatsToogether} details={shareValues} />
        <KeyNumbersCpnt content={keyNumbers} />
        <FoundersCpnt content={founders} />
        <MembersCpnt content={members} />
        {/* <MembersFixedCpnt content={members} /> */}
        <MemberTypeCpnt title="Nos membres adhérents" type="adhérent" />
        {/* <MemberTypeCpnt title='Nos membres bienfaiteurs' type='bienfaiteur' /> */}
        <MemberTypeCpnt title="Nos membres historiques" type="historique" />
        <MemberTypeCpnt title="Nos mécènes et bienfaiteurs" type="mécène" />
        <SupportUsCpnt />
        {/* <CallToActionCpnt content={cta} /> */}
        <TalkAboutUsCpnt content={talking} />
        {/* <SubventionsCpnt content={subventions} /> */}
      </main>
    );
  }
};

export default Home;
