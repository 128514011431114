import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import parse from "html-react-parser";
import { useFetchMenuAPI } from "../../custom-hooks/useFetchPagesAPI";

import { scrollWithOffset } from "../../custom-functions/customFunctions";
import LogoToogether from "../../assets/svg/logo-toogether.svg";
import AccessButtonAtm from "../../atoms/access-button/AccessButtonAtm";

type HeaderCpntProps = {
  handleDisplayMemberPopin: () => void;
};

const HeaderCpnt = ({ handleDisplayMemberPopin }: HeaderCpntProps): JSX.Element => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [isLoading, navmenu, fetchMenuAPI] = useFetchMenuAPI(
    process.env.REACT_APP_API_URL + "/admin/api/singletons/get/Menu"
  );

  const handleBurgerClick = (): void => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    fetchMenuAPI();
  }, []);

  return (
    <header className="header">
      <div className="h-container">
        <div className="hc-logo-wrapper">
          <HashLink to="/#root" scroll={(el) => scrollWithOffset(el, 300)}>
            <img className="hclw-img" src={LogoToogether} alt="logo Toogether" />
          </HashLink>
        </div>
        <nav className={`hc-navbar ${menuIsOpen && "navbar-open"}`}>
          <ul className="hcn-list">
            <HashLink to="/#common-values" scroll={(el) => scrollWithOffset(el, 120)}>
              <li onClick={handleBurgerClick} className="hcnl-item">
                {navmenu ? parse(navmenu.page1 || "Qui est Toogether ?") : "Qui est Toogether ?"}
              </li>
            </HashLink>
            <HashLink to="/#join-us" scroll={(el) => scrollWithOffset(el, 120)}>
              <li onClick={handleBurgerClick} className="hcnl-item">
                {navmenu ? parse(navmenu.page2 || "Toogether") : "Toogether"}
              </li>
            </HashLink>
            <HashLink to="/#share-values" scroll={(el) => scrollWithOffset(el, 90)}>
              <li onClick={handleBurgerClick} className="hcnl-item">
                {navmenu ? parse(navmenu.page3 || "Nos membres") : "Nos membres"}
              </li>
            </HashLink>
            <HashLink to="/#key-numbers" scroll={(el) => scrollWithOffset(el, 300)}>
              <li onClick={handleBurgerClick} className="hcnl-item">
                {navmenu ? parse(navmenu.page4 || "Nos services") : "Nos services"}
              </li>
            </HashLink>
            <HashLink to="/#support-us" scroll={(el) => scrollWithOffset(el, 150)}>
              <li onClick={handleBurgerClick} className="hcnl-item">
                {navmenu ? parse(navmenu.page6 || "Nous soutenir") : "Nous soutenir"}
              </li>
            </HashLink>
            <AccessButtonAtm onClick={handleBurgerClick} textButton="Faire un don" />
          </ul>
        </nav>

        <div onClick={handleBurgerClick} className={`hc-burger ${menuIsOpen && "burger-cross"}`}>
          <span className="hcb-line"></span>
          <span className="hcb-line"></span>
          <span className="hcb-line"></span>
        </div>
      </div>
    </header>
  );
};

export default HeaderCpnt;
