import React from "react";
import AboutArticleCardAtm from "../../atoms/about-article-card/AboutArticleCardAtm";
import { HomePageTalkingInterface } from "../../interfaces/homePageInterfaces";

type TalkingAboutUsProps = {
    content: HomePageTalkingInterface | undefined;
};

const TalkAboutUsCpnt = ({content}: TalkingAboutUsProps): JSX.Element => {
    return (
        <article className="talk-about-us-article">
            <section className="taua-section">
                <h2 className="tauas-title">On parle de nous</h2>
                <div className="tauas-card-wrapper">
                    {content?.articles.map((article, index) => (
                        <AboutArticleCardAtm
                            titleArticle={article.value.article_title}
                            textArticle={article.value.article_description}
                            imgArticle={article.value.article_image.path}
                            linkArticle={article.value.article_link}
                            key={`${index}${article.value.article_title}`}
                        />
                    ))}
                </div>
            </section>
        </article>
    );
};

export default TalkAboutUsCpnt;
