import React, { useState } from "react";
import parse from "html-react-parser";
import ToogetherInfinite from "../../assets/images/toogether-infinite.png";
import ToogetherStage from "../../assets/images/e.png";
import ToogetherFormations from "../../assets/images/ee.png";
import ToogetherMillePas from "../../assets/images/mille_pas.png";
import { HomePageJoinUsInterface } from "../../interfaces/homePageInterfaces";

type NeedToJoinCpntProps = {
  content: HomePageJoinUsInterface | undefined;
};

const NeedToJoinCpnt = ({ content }: NeedToJoinCpntProps) => {
  // const [isFirstList, setIsFirstList] = useState(true);

  // const clickOnArrow = () => {
  //   setIsFirstList(!isFirstList);
  // };

  return (
    <article className="need-to-join-article" id="join-us">
      <section className="ntja-section">
        <div className="ntjas-text">
          {/* <h2 className='njast-title'>{content?.title}</h2> */}
          <h2 className="njast-title">Adhérer à Toogether, c’est agir&nbsp;concrètement pour l’égalité</h2>
          <div className="njast-text">{content ? parse(content.text || "") : ""}</div>
        </div>
        <div className="ntjas-wrapper">
          <div className="ntjasw-coloured-card first">
            <div className="ntjasw-arrow">
              <i
                data-eva="arrow-forward-outline"
                data-eva-fill="#FFF"
                data-eva-height="30"
                data-eva-width="30"
              ></i>
            </div>
            <h3 className="ntjaswcc-title">Nos rencontres inter-entreprises</h3>
            <p className="ntjaswcc-intro">
              Rejoignez un <span className="bold">Cercle de Partage</span>, la clé de notre succès&nbsp;!
            </p>
            <div className="ntjaswcc-list-image-wrapper">
              <img className="ntjaswccliwl-image" src={ToogetherInfinite} alt="" />
              <div className="ntjaswccliw-list-wrapper">
                <ul className="ntjaswccliwlw-list">
                  <li className="ntjaswccliwlwl-item">Partager des bonnes pratiques</li>
                  <li className="ntjaswccliwlwl-item">
                    Résoudre ensemble les <br /> problématiques de sexisme et d'inégalités
                  </li>
                  <li className="ntjaswccliwlwl-item">Créer des actions efficaces</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="ntjasw-coloured-card second">
            <div className="ntjasw-arrow">
              <i
                data-eva="arrow-forward-outline"
                data-eva-fill="#FFF"
                data-eva-height="30"
                data-eva-width="30"
              ></i>
            </div>
            <h3 className="ntjaswcc-title">Nos stages de 3ème</h3>
            <p className="ntjaswcc-intro">
              En partenariat exclusif avec{" "}
              <a href="https://ensemble-en-stage.com/" target="_blank" rel="noreferrer">
                Ensemble en Stage
              </a>
              , <br />
              accueillez un groupe de collégien·nes pour un stage :
            </p>
            <div className="ntjaswcc-list-image-wrapper">
              <img className="ntjaswccliwl-image e-image" src={ToogetherStage} alt="" />
              <div className="ntjaswccliw-list-wrapper">
                <ul className="ntjaswccliwlw-list">
                  <li className="ntjaswccliwlwl-item">
                    pour montrer la diversité et la mixité de vos métiers
                  </li>
                  <li className="ntjaswccliwlwl-item">
                    pour favoriser l’égalité des chances et l’insertion des jeunes
                  </li>
                </ul>
                <a className="bold" href="https://youtu.be/JZX9hFFdzTA" target="_blank" rel="noreferrer">
                  Découvrez nos stages en vidéo
                </a>
              </div>
            </div>
          </div>

          <div className="ntjasw-coloured-card third">
            <div className="ntjasw-arrow">
              <i
                data-eva="arrow-forward-outline"
                data-eva-fill="#FFF"
                data-eva-height="30"
                data-eva-width="30"
              ></i>
            </div>
            <h3 className="ntjaswcc-title">Nos formations exclusives</h3>
            <p className="ntjaswcc-intro">
              En partenariat exclusif avec le leader{" "}
              <a href="https://groupe-egae.fr" target="_blank" rel="noreferrer">
                Egaé
              </a>
              , bénéficiez de formations sur mesure :
            </p>
            <div className="ntjaswcc-list-image-wrapper">
              <img className="ntjaswccliwl-image" src={ToogetherFormations} alt="" />
              <div className="ntjaswccliw-list-wrapper">
                <ul className="ntjaswccliwlw-list">
                  <li className="ntjaswccliwlwl-item">
                    Violences sexistes et sexuelles au travail : prévenir, détecter, réagir
                  </li>
                  <li className="ntjaswccliwlwl-item">Harcèlement : conduire une enquête</li>
                  <li className="ntjaswccliwlwl-item">Sensibiliser les équipes</li>
                  <li className="ntjaswccliwlwl-item">Déconstruire les stéréotypes, etc...</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ntjasw-coloured-card fourth">
            <div className="ntjasw-arrow">
              <i
                data-eva="arrow-forward-outline"
                data-eva-fill="#FFF"
                data-eva-height="30"
                data-eva-width="30"
              ></i>
            </div>
            <h3 className="ntjaswcc-title">Notre jeu de sensibilisation</h3>
            <p className="ntjaswcc-intro">
              En exclusivité à La Réunion, faites participer vos équipes au jeu Les Mille Pas créé par 
              <span> </span>
              <a href="https://www.bejoue.fr/mille-pas/" target="_blank" rel="noreferrer">Bejoue</a>
              &nbsp;:
            </p>
            <div className="ntjaswcc-list-image-wrapper">
              <img className="ntjaswccliwl-image" src={ToogetherMillePas} alt="" />
              <div className="ntjaswccliw-list-wrapper">
                <ul className="ntjaswccliwlw-list">
                  <li className="ntjaswccliwlwl-item">Atelier collaboratif et ludique</li>
                  <li className="ntjaswccliwlwl-item">
                    Comprendre et lever les obstacles aux carrières des femmes
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default NeedToJoinCpnt;
