import React from "react";
import LogoMarianne from "../../assets/images/logo_marianne.png";
import AccessButtonAtm from "../../atoms/access-button/AccessButtonAtm";
import * as eva from "eva-icons";

const SupportUsCpnt = () => {
  return (
    <React.Fragment>
      <article id="support-us" className="support-us">
        <section className="su-container">
          <div className="suc-infos-wrapper">
            <div className="suciw-private info-item">
              <h3 className="ii-title">Particuliers</h3>
              <p className="ii-subtitle">
                Votre don ouvre droit à une réduction d’impôt de 66% (art. 200 CGI).
              </p>
              <div className="ii-list">
                <p className="ii-text">Par exemple :</p>
                <ul className="ii-text list particulier">
                  <li>Vous donnez 1000 €.</li>
                  <li>Vous déduisez 660 € de vos impôts.</li>
                  <li>Vous ne payez en réalité que 340 €.</li>
                </ul>
              </div>
              <a href="/pdf/Toogether_MECENAT_GRAND_PUBLIC.pdf" download className="ii-link">
                Découvrez notre plaquette Grand Public
              </a>
              <span></span>
              {/* <p className="ii-info">Pour faire un don, contactez-nous :</p>
              <a href="mailto:contact.toogether@gmail.com" className="ii-mail">
                <i data-eva-fill="#B93C13" data-eva="email-outline"></i>
                <span>contact.toogether@gmail.com</span>
              </a> */}
              <div className="ii-big-round">
                <div className="iibr-inside-round"></div>
              </div>
            </div>
            <div className="suciw-pro info-item">
              <h3 className="ii-title">Entreprises</h3>
              <p className="ii-subtitle">Votre don est défiscalisé à hauteur de 60% (art. 238 bis CGI).</p>
              <p className="ii-text">Soutenir TOOGETHER, c’est :</p>
              <ul className="ii-text list">
                <li>Contribuer activement à l'égalité</li>
                <li>Faire partie des entreprises championnes</li>
                <li>Bénéficier d’un avantage fiscal</li>
              </ul>
              <a href="/pdf/Toogether_MECENAT_ENTREPRISES.pdf" download className="ii-link">
                Découvrez notre plaquette Entreprise
              </a>
              {/* <p className="ii-info">Pour faire un don, contactez-nous :</p>
              <a href="mailto:contact.toogether@gmail.com" className="ii-mail">
                <i data-eva-fill="#E7AE11" data-eva="email-outline"></i>
                <span>contact.toogether@gmail.com</span>
              </a> */}

              <div className="ii-big-round">
                <div className="iibr-inside-round"></div>
              </div>
            </div>
          </div>
        </section>
        <div className="su-banner">
          <div className="sub-container">
            <div className="subc-text">
              <h2 className="subct-title">Soutenez activement l'égalité des chances</h2>
              <p className="subct-text">Toogether est reconnue d'intérêt général.</p>
              <p className="subct-text">Donnez pour faire progresser l’égalité femmes hommes à La Réunion.</p>
            </div>
            <img className="subc-image" src={LogoMarianne} alt="Association reconnue d'intérêt général" />
          </div>
        </div>
      </article>
      <div className="button-container">
        <AccessButtonAtm textButton="Faire un don" />
      </div>
    </React.Fragment>
  );
};

export default SupportUsCpnt;
