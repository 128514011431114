import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import Legals from "../pages/legals/Legals";

const ProjectRouter = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/legals" component={Legals} />
            <Route path="*" component={Home} />
        </Switch>
    );
};

export default ProjectRouter;
