import React, { useEffect, useState } from "react";

type FounderAtmProps = {
  founderImageSrc: string;
  founderFunction: string;
  founderName: string;
  founderClassName: string;
  founderIndex: number;
};

const FounderAtm = ({
  founderImageSrc,
  founderFunction,
  founderName,
  founderClassName,
  founderIndex,
}: FounderAtmProps): JSX.Element => {
  const [newFunction, setNewFunction] = useState<string[]>();
  useEffect(() => {
    setNewFunction(founderFunction.split(","));
  }, []);
  return (
    <div className={`founder-wrapper ${founderClassName} ${founderIndex % 2 === 0 ? "even" : "odd"}`}>
      <div className="fw-text">
        <h3 className="fwt-name">{founderName}</h3>
        <p className="fwt-position">{newFunction?.length && newFunction[0]}</p>
        <p className="fwt-position">{newFunction?.length === 2 && newFunction[1]}</p>
      </div>
      <div className="fw-image-wrapper">
        <img className="fwiw-image" src={founderImageSrc} alt="" />
      </div>
      <div className="fw-empty"></div>
    </div>
  );
};

export default FounderAtm;
