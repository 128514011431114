import React from "react";
import { HashLink } from "react-router-hash-link";

import { scrollWithOffset } from "../../custom-functions/customFunctions";
import LogoToogether from "../../assets/svg/logo-toogether-white.svg";
import emailSvg from "../../assets/svg/email.svg";
import phoneSvg from "../../assets/svg/phone.svg";
import facebookSvg from "../../assets/svg/facebook.svg";
import tweeterSvg from "../../assets/svg/tweeter.svg";
import linkedinSvg from "../../assets/svg/linkedin.svg";
import youtubeSvg from "../../assets/svg/youtube.svg";
import AccessButtonAtm from "../../atoms/access-button/AccessButtonAtm";

const contact = {
  mail: "contact.toogether@gmail.com",
  phone: "06 92 95 13 40",
};

type FooterCpntProps = {
  handleDisplayMemberPopin: () => void;
};

const FooterCpnt = ({ handleDisplayMemberPopin }: FooterCpntProps): JSX.Element => {
  return (
    <footer className="footer">
      <div className="f-elements-wrapper">
        <section className="few-section section-details">
          <img className="fews-logo" src={LogoToogether} alt="" />
          <a className="fews-mail" href={`mailto:${contact.mail}`}>
            <img src={emailSvg} alt="email" className="fewsm-img" />
            {contact.mail}
          </a>
        </section>
        <section className="few-section section-discover">
          <h4 className="fews-title">Découvrez</h4>
          <ul className="fews-list">
            <li className="fewsl-item">
              <HashLink to="/#common-values" scroll={(el) => scrollWithOffset(el, 120)}>
                Toogether
              </HashLink>
            </li>
            <li className="fewsl-item">
              <HashLink to="/#join-us" scroll={(el) => scrollWithOffset(el, 120)}>
                Nos actions
              </HashLink>
            </li>
            <li className="fewsl-item">
              <HashLink to="/#share-values" scroll={(el) => scrollWithOffset(el, 90)}>
                Nous rejoindre
              </HashLink>
            </li>
            <li className="fewsl-item">
              <HashLink to="/#key-numbers" scroll={(el) => scrollWithOffset(el, 300)}>
                Nos membres
              </HashLink>
            </li>
            <li className="fewsl-item">
              <HashLink to="/#support-us" scroll={(el) => scrollWithOffset(el, 150)}>
                Nous soutenir
              </HashLink>
            </li>
          </ul>
        </section>
        <section className="few-section section-social">
          <h4 className="fews-title">Restez en contact</h4>
          <ul className="fews-list-social">
            <li className="fewsls-item">
              <a
                href="https://www.linkedin.com/company/toogether-égalité-femmes-hommes"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedinSvg} alt="linkedin" />
              </a>
            </li>
            <li className="fewsls-item">
              <a href="https://twitter.com/ToogetherFH" target="_blank" rel="noreferrer">
                <img src={tweeterSvg} alt="twitter" />
              </a>
            </li>
            <li className="fewsls-item">
              <a href="https://www.facebook.com/ToogetherFH" target="_blank" rel="noreferrer">
                <img src={facebookSvg} alt="instagram" />
              </a>
            </li>
            <li className="fewsls-item">
              <a
                href="https://www.youtube.com/channel/UCphUU7NjgOklePn2pMG8agw"
                target="_blank"
                rel="noreferrer"
              >
                <img src={youtubeSvg} alt="youtube" />
              </a>
            </li>
          </ul>
        </section>
        <section className="few-section section-access">
          <h4 className="fews-title">Participez au changement</h4>
          <AccessButtonAtm textButton="Faire un don" />
        </section>
      </div>
      <div className="f-legals">
        <p className="fl-text">
          © 2024 Toogether. Tout droit réservé.{" "}
          <HashLink to="/legals#legals-main" scroll={(el) => scrollWithOffset(el, 120)}>
            Mentions légales&nbsp;
          </HashLink>
          {/* - Site créé avec le coeur par&nbsp;
          <a href='https://www.floax.fr/' className='flt-link'>
            Floax
          </a> */}
        </p>
      </div>
    </footer>
  );
};

export default FooterCpnt;
