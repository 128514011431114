import React from "react";
import ToogetherInfinite from "../../assets/images/toogether-infinite.png";
import { useForm } from "react-hook-form";
import CloseCircleSVG from "../../assets/svg/close-outline.svg";

type PopinMembersProps = {
    isFormdisplayed: boolean;
    handleDisplayMemberPopin: () => void;
};

type MemberFormData = {
    memberLogin: string;
    memberPassword: string;
};

const PopinMembers = ({
    isFormdisplayed,
    handleDisplayMemberPopin,
}: PopinMembersProps): JSX.Element => {
    const { register, handleSubmit } = useForm();
    const handleForm = ({
        memberLogin,
        memberPassword,
    }: MemberFormData): void => {
        // console.log(memberLogin, memberPassword);
    };

    return (
        <aside className={`popin-wrapper ${isFormdisplayed && "popin-active"}`}>
            <div className="pw-popin-members">
                <img
                    onClick={handleDisplayMemberPopin}
                    src={CloseCircleSVG}
                    alt="close button"
                    className="pw-close-button"
                />
                <div className="pwpm-logo-banner">
                    <img
                        className="pwpmlb-logo"
                        src={ToogetherInfinite}
                        alt="logo toogether"
                    />
                    {/* <p className="pwpmlb-text">
                        En action pour l’égalité femme homme
                    </p> */}
                </div>
                <div className="pwpm-form-part">
                    <h2 className="pwpmfp-title">
                        Accédez à votre espace exclusif, réservé aux entreprises
                        membres
                    </h2>
                    <form
                        className="pwpmfp-form"
                        onSubmit={handleSubmit(handleForm)}
                    >
                        <fieldset className="pwpmfpf-login fieldset">
                            <label
                                htmlFor=""
                                className="pwpmfpfl-label f-label"
                            >
                                Identifiant
                            </label>
                            <input
                                className="pwpmfpfl-input f-input"
                                type="text"
                                name="memberLogin"
                                id="memberLogin"
                                ref={register({ required: true })}
                                required
                            />
                        </fieldset>
                        <fieldset className="pwpmfpf-password fieldset">
                            <label
                                htmlFor=""
                                className="pwpmfpfp-label f-label"
                            >
                                Mot de passe
                            </label>
                            <input
                                className="pwpmfpfp-input f-input"
                                type="password"
                                name="memberPassword"
                                id="memberPassword"
                                ref={register({ required: true })}
                                required
                            />
                        </fieldset>
                        <input
                            type="submit"
                            className="pwpmfpf-submit-button"
                            value="Se connecter"
                        />
                    </form>
                    <p className="pwpmfp-forgoten">
                        Vous avez oublié votre mot de passe ?<br />
                        Prévenez : <a href="mailto:contact.toogether@gmail.com">contact.toogether@gmail.com</a>
                    </p>
                </div>
            </div>
        </aside>
    );
};

export default PopinMembers;
