import React from "react";

import { HomePageKeyNumbersInterface } from "../../interfaces/homePageInterfaces";

type KeyNumbersCpntProps = {
  content: HomePageKeyNumbersInterface | undefined;
};

const KeyNumbersCpnt = ({ content }: KeyNumbersCpntProps) => {
  if (content?.isVisible) {
    return (
      <article className='keyNumbers-article'>
        <div className='kna-wrapper'>
          <div id='key-numbers'>
            {content?.numbers.map((number, index) => (
              <div className='knaw-number' key={index}>
                <span className='knawn-value'>{number.value.number}</span>
                <span className='knawn-text'>{number.value.text}</span>
              </div>
            ))}
          </div>
        </div>
      </article>
    );
  } else {
    return <div></div>;
  }
};

export default KeyNumbersCpnt;
