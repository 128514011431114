import React from "react";

type AboutArticleCardAtmProps = {
  titleArticle: string;
  textArticle: string;
  imgArticle: string;
  linkArticle: string;
};

const AboutArticleCardAtm = ({
  titleArticle,
  textArticle,
  imgArticle,
  linkArticle,
}: AboutArticleCardAtmProps): JSX.Element => {
  const cardStyle = {
    backgroundImage: `url(${process.env.REACT_APP_API_URL + imgArticle})`,
  } as React.CSSProperties;

  return (
    <a href={linkArticle} target="_blank" className="about-article-card-wrapper">
      <div className="about-article-card">
        <div className="aac-img" style={cardStyle}></div>
        <h3 className="aac-title">{titleArticle}</h3>
        <p className="aac-text">{textArticle}</p>
      </div>
    </a>
  );
};

export default AboutArticleCardAtm;
