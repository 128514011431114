import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import CarouselItem from "../../interfaces/carouselItemInterface";

const CarouselAtm = ({ imagesDatasArray }: any) => {
  const imagesDatasArrayRandomized = imagesDatasArray.sort(() => Math.random() - Math.random()) || imagesDatasArray;

  return (
    <div className="container">
      <Carousel
        arrows={true}
        slidesPerPage={4}
        infinite
        breakpoints={{
          760: { slidesPerPage: 3 },
          450: { slidesPerPage: 2 },
        }}
        autoPlay={2000}>
        {imagesDatasArrayRandomized.map((image: CarouselItem, index: number) => (
          <div className="members-carrousel" key={index}>
            <img
              src={process.env.REACT_APP_API_URL + "/" + image.value.company_image.path}
              alt={image.value.company_name}
              key={index}
            />
            <span>{image.value.company_name}</span>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselAtm;
