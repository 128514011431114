import { useState } from "react";
import axios from "axios";

import {
  HomePageEqualityInterface,
  HomePageCommonValuesInterface,
  HomePageJoinUsInterface,
  HomePageShareValuesInterface,
  HomePageWhatsToogetherInterface,
  HomePageFoundersInterface,
  HomePageMembersInterface,
  HomePageCTAInterface,
  HomePageTalkingInterface,
  HomePageMenuInterface,
  HomePageKeyNumbersInterface,
  HomePageSubventionsInterface,
} from "../interfaces/homePageInterfaces";

type HomePageItem = {
  component: string;
  settings: {};
};

type MenuItems = {
  page1: string;
  page2: string;
  page3: string;
  page4: string;
  page5: string;
  button: string;
};

export const useFetchHomepageAPI = (link: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [equalityPart, setEqualityPart] = useState<HomePageEqualityInterface>();
  const [commonValues, setCommonValues] = useState<HomePageCommonValuesInterface>();
  const [joinUs, setJoinUs] = useState<HomePageJoinUsInterface>();
  const [shareValues, setShareValues] = useState<HomePageShareValuesInterface>();
  // const [toogetherOrNot, setToogetherOrNot] = useState<HomePageTogetherNotToogetherInterface>();
  const [keyNumbers, setKeyNumbers] = useState<HomePageKeyNumbersInterface>();
  const [founders, setFounders] = useState<HomePageFoundersInterface>();
  const [members, setMembers] = useState<HomePageMembersInterface>();
  // const [partner, setPartner] = useState<HomePagePartnerInterface>();
  const [cta, setCta] = useState<HomePageCTAInterface>();
  const [talking, setTalking] = useState<HomePageTalkingInterface>();
  const [whatsToogether, setWhatsToogether] = useState<HomePageWhatsToogetherInterface>();
  // const [subventions, setSubventions] = useState<HomePageSubventionsInterface>();

  const fetchHomePageAPI = async (): Promise<HomePageItem[]> => {
    const response = await axios.get(link);
    const result = response.data.content[0].children;
    setEqualityPart(result.find((elt: HomePageItem) => elt.component === "Header").settings);
    setCommonValues(result.find((elt: HomePageItem) => elt.component === "Valeurs_Communes").settings);
    setJoinUs(result.find((elt: HomePageItem) => elt.component === "Nous_Rejoindre").settings);
    setShareValues(result.find((elt: HomePageItem) => elt.component === "Partagez_Valeurs").settings);
    // setToogetherOrNot(
    //   result.find((elt: HomePageItem) => elt.component === "Toogether_Not_Toogether").settings
    // );
    setKeyNumbers(result.find((elt: HomePageItem) => elt.component === "Chiffres_Cles").settings);
    setFounders(result.find((elt: HomePageItem) => elt.component === "Membres_Fondateurs").settings);
    setMembers(result.find((elt: HomePageItem) => elt.component === "Entreprises_Membres").settings);
    // setPartner(result.find((elt: HomePageItem) => elt.component === "Notre_partenaire").settings);
    setCta(result.find((elt: HomePageItem) => elt.component === "Des_Questions").settings);
    setTalking(result.find((elt: HomePageItem) => elt.component === "On_parle_de_nous").settings);
    setWhatsToogether(result.find((elt: HomePageItem) => elt.component === "whatsToogether").settings);
    // setSubventions(result.find((elt: HomePageItem) => elt.component === "Subventions").settings);
    setIsLoading(false);
    return result;
  };

  return [
    isLoading,
    equalityPart,
    commonValues,
    joinUs,
    shareValues,
    // toogetherOrNot,
    keyNumbers,
    founders,
    members,
    // partner,
    cta,
    talking,
    whatsToogether,
    // subventions,
    fetchHomePageAPI,
  ] as const;
};

export const useFetchMenuAPI = (link: string) => {
  const [isLoading, setIsLoading] = useState(true);
  const [navmenu, setNavMenu] = useState<HomePageMenuInterface>();

  const fetchMenuAPI = async (): Promise<MenuItems> => {
    const response = await axios.get(link);
    const result = response.data;
    setNavMenu(result);

    setIsLoading(false);
    return result;
  };

  return [isLoading, navmenu, fetchMenuAPI] as const;
};
