import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import FooterCpnt from "./components/footer/FooterCpnt";
import smoothscroll from "smoothscroll-polyfill";
import HeaderCpnt from "./components/header/HeaderCpnt";
import ProjectRouter from "./router/ProjectRouter";
import PopinMembers from "./components/popin-members/PopinMembers";

smoothscroll.polyfill();

function App(): JSX.Element {
  const [isMemberPopindisplayed, setIsMemberPopindisplayed] = useState<boolean>(false);

  const handleDisplayMemberPopin = (): void => {
    setIsMemberPopindisplayed(!isMemberPopindisplayed);
  };

  return (
    <div className="App">
      <Router basename={process.env.REACT_APP_ROUTER_BASE}>
        <HeaderCpnt handleDisplayMemberPopin={handleDisplayMemberPopin} />
        <ProjectRouter />
        <PopinMembers
          isFormdisplayed={isMemberPopindisplayed}
          handleDisplayMemberPopin={handleDisplayMemberPopin}
        />
        <FooterCpnt handleDisplayMemberPopin={handleDisplayMemberPopin} />
      </Router>
    </div>
  );
}

export default App;
