import React from "react";
import { HashLink } from "react-router-hash-link";
import parse from "html-react-parser";

import { scrollWithOffset } from "../../custom-functions/customFunctions";
import { HomePageEqualityInterface } from "../../interfaces/homePageInterfaces";

type EqualityCpntProps = {
  content: HomePageEqualityInterface | undefined;
};

const EqualityCpnt = ({ content }: EqualityCpntProps): JSX.Element => {
  return (
    <article className='equality-article' id='equality-article'>
      <div className='ea-section-wrapper'>
        <section className='ea-section'>
          <h2 className='eas-title'>
            {content
              ? parse(content.title || "")
              : "Des <span>entreprises</span> à l’avant‑garde de l’égalité femmes hommes"}
          </h2>
          <p className='eas-text'>{content ? parse(content.text || "") : ""}</p>
          <div className='eas-links-wrapper'>
            <HashLink to='/#share-values' scroll={(el) => scrollWithOffset(el, 90)} className='easlw-link-join'>
              {content?.button[0].value}
            </HashLink>

            <HashLink to='/#members-cpnt' scroll={(el) => scrollWithOffset(el, 120)} className='easlw-link-members'>
              {content?.button[1].value}
            </HashLink>
          </div>
        </section>
      </div>
    </article>
  );
};

export default EqualityCpnt;
