import React, { useEffect } from "react";
import {
  HomePageShareValuesInterface,
  HomePageWhatsToogetherInterface,
} from "../../interfaces/homePageInterfaces";
import * as eva from "eva-icons";
import { HashLink } from "react-router-hash-link";
import { scrollWithOffset } from "../../custom-functions/customFunctions";

type ToogetherCpntProps = {
  content: HomePageWhatsToogetherInterface | undefined;
  details: HomePageShareValuesInterface | undefined;
};

const ToogetherCpnt = ({ content, details }: ToogetherCpntProps) => {
  let cardsLink = ['#support-us', '#join-us'];
  useEffect(() => {
    // add this line
    eva.replace();
  }, []);
  return (
    <article className='whats-toogether-article' id='share-values'>
      <div className='wta-sections-wrapper'>
        <h2 className='wtasw-title'>{content?.title}</h2>
        <section className='wtasw-cards-wrapper'>
          {content?.cards.map((card, index) => (
            <div className='wtaswcw-card' key={index}>
              <h3 className='wtaswcwc-title'>{card.value.title}</h3>
              <p className='wtaswcwc-subtitle'>{card.value.subtitle}</p>
              <HashLink to={`${cardsLink[index]}`} scroll={(el) => scrollWithOffset(el, 150)}>
                <a href="#support-us" className=''>
                  <div
                    className='wtaswcwc-img'
                    style={{
                      backgroundImage: `url( ${
                        process.env.REACT_APP_API_URL &&
                        process.env.REACT_APP_API_URL + details?.photos[0].value.path
                      })`,
                    }}
                  ></div>
                </a>  
                </HashLink>
              <div className='wtaswcwc-details-wrapper'>
                <a href={`mailto:${card.value.mail}`} className='taswccdw-mail'>
                  <i data-eva-fill='#C84115' data-eva='email-outline'></i>
                  <span className='detail'>{card.value.mail}</span>
                </a>
              </div>
            </div>
          ))}
        </section>
        <section className='tasw-list'>
          <h2 className='taswtl-title' id='toogether-or-not'>
            {content?.list.title}
          </h2>
          <ul className='taswtl-list'>
            {content?.list.items.map((elt, index) => (
              <li className='taswtll-item' key={index}>
                <span className='taswtlli-who'>{elt.value.title}</span>
                <br />
                {elt.value.text}
              </li>
            ))}
          </ul>
        </section>
      </div>
      <div className='ta-clip-path-bottom'></div>
    </article>
  );
};

export default ToogetherCpnt;
