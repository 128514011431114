import React from "react";
// import CountUp from 'react-countup';

import CarouselAtm from "../../atoms/carousel/CarouselAtm";
import { HomePageMembersInterface } from "../../interfaces/homePageInterfaces";

type MembersCpntProps = {
  content: HomePageMembersInterface | undefined;
};

const MembersCpnt = ({ content }: MembersCpntProps) => {
  return (
    <article className='members-article'>
      <section className='masw-members'>
        <h2 className='maswm-title' id='members-cpnt'>
          Nos entreprises engagées
        </h2>

        {/*Wait for deployment}
                {/* <CountUp
                start={0}
                end={15}
                duration={1.5}
                suffix=" entreprises"
                />
                <CountUp
                start={0}
                end={450}
                duration={1.5}
                suffix=" salarié.es"
                /> */}
        <CarouselAtm imagesDatasArray={content?.companies} />
      </section>
    </article>
  );
};

export default MembersCpnt;
