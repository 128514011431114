import React from "react";

type AccessButtonProps = {
  textButton: string;
  onClick?: () => void;
};

const AccessButtonAtm = ({ textButton, onClick }: AccessButtonProps): JSX.Element => {
  return (
    <a href="https://www.helloasso.com/associations/toogether/formulaires/1">
      <button className="access-button" onClick={onClick}>
        {textButton}
      </button>
    </a>
  );
};

export default AccessButtonAtm;
